import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading from "../assets/Loading/loading";

const Home = lazy(() => import("../pages/Home/Home"));
const Admissions = lazy(() => import("../pages/Admissions/Index"));
const Students = lazy(() => import("../pages/Students/Index"));
const More = lazy(() => import("../pages/More/Index"));
const Profile = lazy(() => import("../pages/More/Profile/Index"));
const Coaches = lazy(() => import("../pages/More/Coaches/Index"));
const AddCoach = lazy(() => import("../pages/More/Coaches/AddCoach"));
const EditCoach = lazy(() => import("../pages/More/Coaches/EditCoach"));
const Courses = lazy(() => import("../pages/More/Courses/Index"));
const AddCourse = lazy(() => import("../pages/More/Courses/AddCourse"));
const EditCourse = lazy(() => import("../pages/More/Courses/EditCourse"));
const RatingsReviews = lazy(() => import("../pages/More/Ratings/Index"));
const AddStudent = lazy(() => import("../pages/Admissions/AddStudent"));
const EditStudent = lazy(() => import("../pages/Admissions/EditStudent"));
const AssignStudent = lazy(() => import("../pages/Students/AssignStudentList"));
const StudentDetails = lazy(() => import("../pages/Students/StudentDetails"));
const AddStudentSuccess = lazy(() => import("../pages/Admissions/AddStudentSuccess"));
const StudentAdmissionDetails = lazy(() => import("../pages/Admissions/StudentDetails"));
const CourseDetails = lazy(() => import("../pages/Students/CourseDetails"));
const MarkAttendance = lazy(() => import("../pages/Students/MarkAttendance"));
const Transactions = lazy(() => import("../pages/Transactions/Index"));
const Subscriptions = lazy(() => import("../pages/More/Subscriptions/Subscriptions"));
const ImportStudents = lazy(() => import("../pages/More/UploadStudents/Index"));
const AddPaymentDetails = lazy(() => import("../pages/Transactions/AddPaymentDetails"));
const Leads = lazy(() => import("../pages/Leads/Index"));
const SubscriptionExpired = lazy(() => import("../pages/More/Subscriptions/SubscriptionExpired"));
const NewSubscription = lazy(() => import("../pages/More/Subscriptions/NewSubscription"));
const SubscriptionSuccess = lazy(() => import("../pages/More/Subscriptions/SubscriptionSuccess"));
const SubscriptionFailed = lazy(() => import("../pages/More/Subscriptions/SubscriptionError"));
const SubscriptionPending = lazy(() => import("../pages/More/Subscriptions/SubscriptionPending"));


//User Pages
const AcademyDetails = lazy(() => import("../pages/User/AcademyDetails/Index"));
const CreateReview = lazy(() => import("../pages/User/AcademyDetails/CreateReview"));
const UpdateReview = lazy(() => import("../pages/User/AcademyDetails/UpdateReview"));
const Bookings  = lazy(() => import("../pages/User/Bookings/Index"));
const Search = lazy(() => import("../pages/User/Search/Index"));
const UserProfile = lazy(() => import("../pages/User/More/UpdateProfile"));
const Enroll = lazy(() => import("../pages/User/AcademyDetails/Enroll/Enroll"));
const PaymentDetails = lazy(() => import("../pages/User/AcademyDetails/Enroll/PaymentDetails"));
const EnrollSuccess = lazy(() => import("../pages/User/AcademyDetails/Enroll/EnrollSuccess"));


const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/admission" exact component={Admissions} />
            <Route path="/courses" exact component={Students} />
            <Route path="/more" exact component={More} />
            <Route path="/subscription-expired" exact component={SubscriptionExpired} />
            <Route path="/more/profile" exact component={Profile} />
            <Route path="/more/coaches" exact component={Coaches} />
            <Route path="/more/add-coach" exact component={AddCoach} />
            <Route path="/more/edit-coach/:coachID" exact component={EditCoach} />
            <Route path="/more/courses" exact component={Courses} />
            <Route path="/more/add-course" exact component={AddCourse} />
            <Route path="/more/edit-course/:courseID" component={EditCourse} />
            <Route path="/more/ratings-reviews" exact component={RatingsReviews} />
            <Route path="/more/transactions" exact component={Transactions} />
            <Route path="/more/subscriptions" exact component={Subscriptions} />
            <Route path="/more/new-subscription" exact component={NewSubscription} />
            <Route path="/more/subscription-success" exact component={SubscriptionSuccess} />
            <Route path="/more/subscription-failed" exact component={SubscriptionFailed} />
            <Route path="/more/subscription-pending" exact component={SubscriptionPending} />
            <Route path="/more/import-student-data" exact component={ImportStudents} />
            <Route path="/more/add-payment" exact component={AddPaymentDetails} />
            <Route path="/more/leads" exact component={Leads} />
            <Route path="/admission/add-student" exact component={AddStudent} />
            <Route path="/admission/:studentID/edit-student" exact component={EditStudent} />
            <Route path="/admission/success" exact component={AddStudentSuccess} />
            <Route path="/course/:courseID/assign-student" exact component={AssignStudent} />
            <Route path="/admission/student-details" exact component={StudentAdmissionDetails} />
            <Route path="/course/:courseID/course-details" exact component={CourseDetails} />
            <Route path="/course/:courseID/mark-attendance" exact component={MarkAttendance} />
            <Route path="/course/:courseID/student/:studentID/details" exact component={StudentDetails} />
            {/* User Pages */}
            <Route path="/academy/:academyID/details" exact component={AcademyDetails} />
            <Route path="/academy/:academyID/details/courses" exact component={AcademyDetails} />
            <Route path="/user/:academyID/create-review" exact component={CreateReview} />
            <Route path="/user/:academyID/update-review" exact component={UpdateReview} />
            <Route path="/bookings" exact component={Bookings} />
            <Route path="/search" exact component={Search} />
            <Route path="/more/user-profile" exact component={UserProfile} />
            <Route path="/enroll" exact component={Enroll} />
            <Route path="/payment-details" exact component={PaymentDetails} />
            <Route path="/enroll-success" exact component={EnrollSuccess} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
